import { render, staticRenderFns } from "./MailSent.vue?vue&type=template&id=3d591484&scoped=true&"
import script from "./MailSent.vue?vue&type=script&lang=ts&"
export * from "./MailSent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d591484",
  null
  
)

export default component.exports